const timeouts = {};

const state = {
  queue: [],
};

const getters = {};

const actions = {
  addNotification({ commit }, { type, msg }) {
    const id = `${Math.random()}`;

    commit('ADD_NOTIFICATION', {
      type,
      msg,
      id,
    });

    timeouts[id] = setTimeout(() => {
      commit('DELETE_LAST_NOTIFICATION');
    }, 4000);
  },
};

const mutations = {
  ADD_NOTIFICATION(state, notification) {
    state.queue = [notification, ...state.queue];
  },
  DELETE_LAST_NOTIFICATION(state) {
    const queue = [...state.queue];
    queue.pop();
    state.queue = queue;
  },
  DELETE_NOTIFICATION(state, id) {
    clearTimeout(timeouts[id]);
    const queue = state.queue.filter((notification) => notification.id !== id);
    state.queue = queue;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
