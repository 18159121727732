<template>
  <div class="CalendarPublicHour">
    <template v-for="timeSlot in hour">
      <CalendarPublicTimeSlotBookingEnabled v-if="bookingEnabled"
                                            :is-booked="isBooked(timeSlot.timestamp)"
                                            :is-available="isAvailable(timeSlot.timestamp)"
                                            :is-in-basket="isInBasket(timeSlot.timestamp)"
                                            :timestamp="timeSlot.timestamp"
                                            :available-slots="availableSlots"
                                            :booked-slots="bookedSlots"
                                            :time-display="timeSlot.display"
      />
      <CalendarPublicTimeSlot v-else
                              :is-booked="isBooked(timeSlot.timestamp)"
                              :is-available="isAvailable(timeSlot.timestamp)"
                              :timestamp="timeSlot.timestamp"
                              :time-display="timeSlot.display"
      />
    </template>
  </div>
</template>

<script>
import calendarHelpers from '@/common/mixins/calendarHelpers';
import basketHelpers from '@/common/mixins/basketHelpers';
import CalendarPublicTimeSlot from './CalendarPublicTimeSlot.vue';
import CalendarPublicTimeSlotBookingEnabled from './CalendarPublicTimeSlotBookingEnabled.vue';

export default {
  name: 'CalendarPublicHour',
  mixins: [calendarHelpers, basketHelpers],
  components: {
    CalendarPublicTimeSlot,
    CalendarPublicTimeSlotBookingEnabled,
  },
  props: {
    hour: {
      type: Array,
    },
    bookingEnabled: {
      type: Boolean,
    },
    availableSlots: {
      type: Object,
    },
  },
  methods: {
    isBooked(timestamp) {
      return !!(this.availableSlots[timestamp] && this.bookedSlots[timestamp]);
    },
    isAvailable(timestamp) {
      return !!this.availableSlots[timestamp];
    },
    isInBasket(timestamp) {
      return !!(this.availableSlots[timestamp] && this.basketItems[timestamp]);
    },
  },
};
</script>

<style lang="scss">
  $calendar_cell_height_public: 80px;

  .CalendarPublicHour {
    display: flex;
    min-height: $calendar_cell_height_public;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    flex-direction: column;
    align-items: stretch;
  }
</style>
