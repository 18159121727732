export default class TutorsApi {
  constructor(db, state) {
    this.db = db;
    this.state = state;
  }

  getTutorByRouteName(route) {
    return this.db.collection('teachers_public').where('route', '==', route).limit(1).get();
  }

  getTeacher(teacherId) {
    return this.db.collection('teachers').doc(teacherId).get();
  }

  getPublicSettings() {
    return this.db.collection('teachers_public').doc(this.state.authentication.authenticatedTeacherId).get();
  }

  getBusinessCardSettings(teacherId, isDraft) {
    const name = isDraft ? 'teachers_business_card_draft' : 'teachers_business_card';
    return this.db.collection(name).doc(teacherId || this.state.authentication.authenticatedTeacherId).get();
  }

  updatePublicSettings(publicSettings) {
    return this.db.collection('teachers_public').doc(this.state.authentication.authenticatedTeacherId).update(publicSettings);
  }

  updateSettings(settings) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).update(settings);
  }

  updateBusinessCardSettings(businessCardSettings, isDraft) {
    const name = isDraft ? 'teachers_business_card_draft' : 'teachers_business_card';
    return this.db.collection(name).doc(this.state.authentication.authenticatedTeacherId).set(businessCardSettings, { merge: true });
  }

  loadTeacherPriceLists() {
    const teacherId = this.state.tutorPage.teacherId;

    return this.db.collection(`teachers/${teacherId}/priceLists`).get();
  }

  async publishBusinessCardSettings() {
    const doc = await this.db.collection('teachers_business_card_draft').doc(this.state.authentication.authenticatedTeacherId).get();
    const currentSettings = doc.data();
    return this.updateBusinessCardSettings(currentSettings, false);
  }

  stripeRegister(data) {
    const extendData = {
      ...data,
      teacherId: this.state.authentication.authenticatedTeacherId
    };

    return firebase.functions().httpsCallable('stripeRegister')(extendData);
  }

  stripeVerifyRegistration() {
    const data = {
      teacherId: this.state.authentication.authenticatedTeacherId,
    };

    return firebase.functions().httpsCallable('stripeVerifyRegistration')(data);
  }
}
