export default class BasketApi {
  constructor(db, state) {
    this.db = db;
    this.state = state;
  }

  getCoupon(coupon) {
    const data = {
      teacherId: this.state.tutorPage.teacherId,
      coupon,
    };

    return firebase.functions().httpsCallable('getCoupon')(data);
  }
}
