import moment from 'moment';

export default class EventsApi {
  constructor(db, state) {
    this.db = db;
    this.state = state;
  }

  saveEvent(event) {
    const teacherRef = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId);
    let eventRef;
    let repeatingEventRef;
    const eventsBatch = this.db.batch();

    if (event.repeatWeekly) {
      const getRandomString = () => Math.random().toString(36).substr(2, 5);
      const docName = event.start + getRandomString();
      repeatingEventRef = teacherRef.collection('repeatingEvents').doc(docName);

      let week = 0;
      let startTime = 0;
      const endDate = moment(event.endDateRepeatWeekly).endOf('day').valueOf();

      do {
        if (week === 0) {
          eventRef = teacherRef.collection('events').doc(event.id);
        } else {
          eventRef = teacherRef.collection('events').doc();
        }

        startTime = moment(event.start).add(week, 'weeks').valueOf();

        eventsBatch.set(eventRef, {
          start: startTime,
          end: moment(event.start).add(week, 'weeks').valueOf(),
          studentId: event.studentId,
          length: event.length,
          repeatWeekly: true,
          parentId: docName,
          priceListId: event.priceListId
        });

        week = week + 1;
      } while (endDate >= moment(event.start).add(week, 'weeks').valueOf());

      eventsBatch.set(repeatingEventRef, {
        active: true,
        length: event.length,
        studentId: event.studentId,
        lastStart: startTime,
      });
    } else {
      const docId = event.id;
      const eventWithoutId = { ...event };
      delete eventWithoutId.id;

      eventRef = teacherRef.collection('events').doc(docId);
      eventsBatch.set(eventRef, eventWithoutId);
    }

    return eventsBatch.commit();
  }

  loadEvents(fromTimestamp, toTimestamp) {
    const eventsRef = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('events')
      .where('start', '>=', fromTimestamp)
      .where('start', '<', toTimestamp);

    return eventsRef.get();
  }

  setCutOffForRepeatingEvent(parentId, cutOffTimestamp) {
    const eventsBatch = this.db.batch();
    const repeatingEventRef = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('repeatingEvents').doc(parentId);

    const childEventsQuery = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('events')
      .where('parentId', '==', parentId)
      .where('start', '>=', cutOffTimestamp);

    return childEventsQuery.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        eventsBatch.delete(doc.ref);
      });

      eventsBatch.update(repeatingEventRef, { active: false });

      return eventsBatch.commit();
    });
  }

  editEvent(eventId, details) {
    const eventRef = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('events').doc(eventId);
    return eventRef.update(details);
  }

  deleteEvent(eventId) {
    const eventRef = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('events').doc(eventId);
    return eventRef.delete();
  }

  deleteRepeatingEvent(parentId) {
    const eventsBatch = this.db.batch();
    const repeatingEventRef = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('repeatingEvents').doc(parentId);

    const childEventsQuery = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('events').where('parentId', '==', parentId);

    return childEventsQuery.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        eventsBatch.delete(doc.ref);
      });

      eventsBatch.delete(repeatingEventRef);

      return eventsBatch.commit();
    });
  }

  loadVacations(fromTimestamp, toTimestamp) {
    const vacationsQuery = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('vacations')
      .where('start', '>=', fromTimestamp)
      .where('start', '<', toTimestamp);

    return vacationsQuery.get();
  }

  saveVacation(vacation) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('vacations').doc()
      .set(vacation);
  }

  deleteVacation(start) {
    const vacationsQuery = this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('vacations')
      .where('start', '==', start);

    vacationsQuery.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });
    });
  }

  getStudentEvents(studentId) {
    const teacherId = studentId ? this.state.authentication.authenticatedTeacherId : this.state.tutorPage.teacherId;

    const eventsQuery = this.db.collection('teachers').doc(teacherId).collection('events').where('studentId', '==', studentId || this.state.authentication.authenticatedStudentId);
    return eventsQuery.get();
  }
}
