<template>
  <div class="CalendarHeader">
    <div class="CalendarHeader__button CalendarHeader__button--left">
      <button v-if="showGoEarlierButton" class="circular ui icon button" @click="goEarlier()">
        <i class="icon large arrow alternate circle left outline"></i>
      </button>
    </div>
    <div class="CalendarHeader__date">{{ title }}</div>
    <div class="CalendarHeader__button CalendarHeader__button--right">
      <button v-if="showTodayButton" class="ui large button CalendarHeader__button--today" @click="goToday()">
        Dzisiaj
      </button>
      <button v-if="showGoLaterButton" class="circular ui icon button"  @click="goLater()">
        <i class="icon large arrow alternate circle right outline"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    goToday: {
      type: Function,
      required: true,
    },
    goLater: {
      type: Function,
      required: true,
    },
    goEarlier: {
      type: Function,
      required: true,
    },
    showTodayButton: {
      type: Boolean,
      default: true,
    },
    showGoEarlierButton: {
      type: Boolean,
      default: true,
    },
    showGoLaterButton: {
      type: Boolean,
      default: true,
    },
  },
  name: 'CalendarHeader',
};
</script>

<style lang="scss">
.CalendarHeader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  .CalendarHeader__date {
    font-size: 2em;
    padding: 15px 0;
  }

  .CalendarHeader__button {
    position: absolute;
  }

  .CalendarHeader__button--today {
    margin-right: 30px;
    background-color: wheat;

    &:hover, &:focus {
      background-color: wheat;
    }
  }

  .CalendarHeader__button--right {
    right: 0;
  }

  .CalendarHeader__button--left {
    left: 0;
  }
}
</style>
