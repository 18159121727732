import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import basket from './modules/basket';
import dashboard from './modules/dashboard';
import tutorPage from './modules/tutorPage';
import calendar from './modules/calendar';
import authentication from './modules/authentication';
import notifications from './modules/notifications';
import studentDashboard from './modules/studentDashboard';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['basket'],
  reducer: (state) => ({
    basket: {
      items: state.basket.items
    }
  })
});

const store = new Vuex.Store({
  modules: {
    authentication,
    basket,
    dashboard,
    tutorPage,
    calendar,
    notifications,
    studentDashboard,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
