<template>
  <div class="ui active dimmer" :class="{'inverted': isInverted}">
    <div class="ui massive text loader">{{text}}</div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    text: {
      type: String,
      default: '',
    },
    isInverted: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
