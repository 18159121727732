export default class CalendarApi {
  constructor(db, state) {
    this.db = db;
    this.state = state;
  }

  isSlotAvailable({ studentTimezone, timestamp, length }) {
    const data = {
      teacherId: this.state.tutorPage.teacherId,
      studentTimezone,
      timestamp,
      length,
    };

    return firebase.functions().httpsCallable('isSlotAvailable')(data);
  }

  changeEventDate({ eventId, newStart, timezone }) {
    const data = {
      teacherId: this.state.tutorPage.teacherId,
      eventId,
      newStart,
      timezone,
    };

    return firebase.functions().httpsCallable('changeEventDate')(data);
  }
}
