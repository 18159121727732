<template>
  <div class="calendar-private-time-slot-start-event">
    <div class="calendar-private-event"
         :style="{
                  height: timeSlotsLength * 100 + '%',
                  backgroundColor: getStudentColor(studentId),
                  color: getStudentFontColor(studentId)
               }"
         :class="[{
                    'CalendarMatrix__event': true,
                    'CalendarMatrix__event--repeating': isRepeatedWeekly,
                    '--external-booking': isPaidBooking && !studentId,
                    '--small': timeSlotsLength === 1,
                    '--medium': timeSlotsLength === 2,
                    '--paid': isPaid,
                    '--temporary': isTemporaryBlocked,
                   }]"
    >
      <div class="calendar-private-event__actions">
        <i class="dollar icon link"></i>
      </div>
      <div class="calendar-private-event__details" @click.stop="$root.$emit('open-edit-booking-modal', timestamp)">
        <div class="calendar-private-event__name">{{ label }}</div>
        <div v-if="getStudent(studentId).location" class="calendar-private-event__location">
          {{ startingHour }} @ {{ getStudent(studentId).location }}
        </div>
      </div>

      <div class="ui flowing popup top left transition hidden calendar-private-event__money-popup">
        <div class="ui three column divided center aligned grid" :data-t="timestamp">
          <div v-if="isPaid && transactionId" class="ui segment">
            <h5 class="ui header">
              Id transakcji: <br /> {{ transactionId }}
            </h5>
          </div>
          <div v-else class="ui segment">
            <h4 class="ui header">Oznacz lekcją jako</h4>
            <div v-if="isPaid" class="ui negative button" @click="setPayment(false)">nieopłaconą</div>
            <div v-else class="ui positive button" @click="setPayment(true)">opłaconą</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import calendarHelpers from '../../../mixins/calendarHelpers';

export default {
  name: 'CalendarPrivateTimeSlot',
  mixins: [calendarHelpers],
  props: {
    timeSlotsLength: {
      type: Number,
      default: 1,
    },
    studentId: {
      type: String,
    },
    studentName: {
      type: String,
    },
    startingHour: {
      type: String,
    },
    timestamp: {
      type: Number,
    },
    isPaidBooking: {
      type: Boolean,
    },
    isRepeatedWeekly: {
      type: Boolean,
    },
    isTemporaryBlocked: {
      type: Boolean,
    },
    isPaid: {
      type: Boolean,
    },
    transactionId: {
      type: String,
      default: null,
    },
  },
  mounted() {
    $(this.$el).find('.calendar-private-event__actions').popup({
      popup: $(this.$el).find('.calendar-private-event__money-popup'),
      hoverable: true,
      exclusive: true,
      lastResort: true,
      on: 'click',
      inline: true,
      delay: {
        show: 0,
        hide: 500,
      },
    });
  },
  computed: {
    label() {
      return this.isTemporaryBlocked ? 'W trakcie realizacji' : this.studentName ? this.studentName : '';
    },
  },
  methods: {
    setPayment(status) {
      this.$root.$emit('update-payment-status', this.timestamp, status);
      $(this.$el).find('.calendar-private-event__actions').popup('hide');
    },
    getStudentColor(studentId) {
      let student;

      if (studentId) {
        student = this.students.find((student) => student.id == studentId) || {};
      }

      return student && student.color ? student.color : '#00c0bb';
    },

    getStudentFontColor(studentId) {
      const backgroundColor = this.getStudentColor(studentId);
      let color = '#fff';

      if (backgroundColor === 'yellow') {
        color = '#000';
      }

      return color;
    },
  }
};
</script>

<style lang="scss">
  @mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .calendar-private-time-slot-start-event {
    position: relative;
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 25%;

    .calendar-private-event {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      display: flex;

      &__actions {
        min-width: 16px;
        width: 16px;
        background-color: #e40000;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          position: relative;
          top: -2px;
          right: -2px;
        }
      }

      &__details {
        overflow: hidden;
        flex-grow: 1;
        padding: 0 5px;
      }

      &.--temporary {
        .calendar-private-event__actions, .calendar-private-event__location {
          display: none;
        }
      }

      &.--external-booking {
        background-color: #607d8b !important;
        word-break: break-all;

        .calendar-private-event__location {
          display: none;
        }
      }

      &.--paid {
        .calendar-private-event__actions {
          background-color: #4caf50;
        }
      }

      &.--small {
        border-bottom: none;

        .calendar-private-event__location {
          display: none;
        }
      }

      &.--small, &.--medium {
        .calendar-private-event__name {
          @include ellipsis;
        }

        .calendar-private-event__location {
          @include ellipsis;
        }
      }
    }
  }
</style>
