<template>
  <div class="CalendarPrivateTimeSlot"
       @click.stop="$root.$emit('open-booking-modal', timestamp)"
  >
    <span>{{ timeDisplay }}</span>
  </div>
</template>

<script>
export default {
  name: 'CalendarPrivateTimeSlot',
  props: {
    timeDisplay: {
      type: String,
    },
    timestamp: {
      type: Number,
    },
  },
};
</script>

<style lang="scss">
  .CalendarPrivateTimeSlot {
    position: relative;
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 25%;

    &:hover {
      background-color: #19a974;
      transition: background-color .15s ease-in-out;

      > span {
        visibility: visible;
      }
    }

    > span {
      visibility: hidden;
    }
  }
</style>
