import Api from '@/api/api';

const state = {
  authenticatedTeacherId: null,
  authenticatedStudentId: null,
  studentData: null,
  isAuthenticationConfirmationPending: true,
  isLoginModalOpen: false,
  isChangingPassword: false,
};

const getters = {};

const actions = {
  studentSignedIn({ commit }, studentDoc) {
    commit('SET_AUTHENTICATED_STUDENT_ID', studentDoc.id);
    commit('SET_STUDENT_DATA', studentDoc.data());
    commit('SET_IS_AUTHENTICATION_CONFIRMATION_PENDING', false);
  },
  teacherSignedIn({ commit }, teacherDoc) {
    commit('SET_AUTHENTICATED_TEACHER_ID', teacherDoc.id);
    commit('SET_AUTHENTICATED_STUDENT_ID', null);
    commit('SET_STUDENT_DATA', null);
    commit('SET_IS_AUTHENTICATION_CONFIRMATION_PENDING', false);
  },
  notValidStudentSignedIn({ commit }) {
    commit('SET_AUTHENTICATED_STUDENT_ID', null);
    commit('SET_IS_AUTHENTICATION_CONFIRMATION_PENDING', false);
  },
  notValidTeacherSignedIn({ commit }) {
    commit('SET_AUTHENTICATED_TEACHER_ID', null);
    commit('SET_IS_AUTHENTICATION_CONFIRMATION_PENDING', false);
  },
  userSignedOut({ commit }) {
    commit('SET_AUTHENTICATED_TEACHER_ID', null);
    commit('SET_AUTHENTICATED_STUDENT_ID', null);
    commit('SET_IS_AUTHENTICATION_CONFIRMATION_PENDING', false);
  },
  changeTutorPassword({ commit, dispatch }, data) {
    commit('SET_IS_CHANGING_PASSWORD', true);

    Api.changePassword(data.password, data.newPassword)
      .then(() => {
        dispatch('addNotification', { type: 'success', msg: 'Hasło zostało zmienione.' });
      })
      .catch((error) => {
        dispatch('addNotification', { type: 'failure', msg: 'Wystąpił błąd podczas zmiany hasła.' });
      })
      .finally(() => {
        commit('SET_IS_CHANGING_PASSWORD', false);
      });
  },
};

const mutations = {
  SET_AUTHENTICATED_TEACHER_ID(state, teacherId) {
    state.authenticatedTeacherId = teacherId;
  },
  SET_AUTHENTICATED_STUDENT_ID(state, studentId) {
    state.authenticatedStudentId = studentId;
  },
  SET_IS_AUTHENTICATION_CONFIRMATION_PENDING(state, isPending) {
    state.isAuthenticationConfirmationPending = isPending;
  },
  SET_IS_LOGIN_MODAL_OPEN(state, isOpen) {
    state.isLoginModalOpen = isOpen;
  },
  SET_STUDENT_DATA(state, data) {
    state.studentData = data;
  },
  SET_IS_CHANGING_PASSWORD(state, isChanging) {
    state.isChangingPassword = isChanging;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
