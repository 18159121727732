/* eslint-disable class-methods-use-this */
export default class PriceListsApi {
  constructor(db, state) {
    this.db = db;
    this.state = state;
  }

  addPriceList(priceList) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('priceLists').add(priceList);
  }

  loadPriceLists() {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('priceLists').orderBy('name').get();
  }

  updatePriceList({ priceList, priceListId }) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('priceLists').doc(priceListId)
      .update(priceList);
  }

  deletePriceList(priceListId) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('priceLists').doc(priceListId)
      .delete();
  }
}
