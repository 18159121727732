export const StudentDefaultShape = {
  id: null,
  firstName: null,
  lastName: null,
  color: null,
  email: null,
  location: null,
  info: null,
  phone: null,
  prices: null,
  sendingInvitation: null,
  registrationKey: null,
};

export const PriceListDefaultShape = {
  name: '',
  displayLength: '',
  length: 0,
  packages: []
};

export const EventDefaultShape = {
  id: null,
  start: null,
  end: null,
  repeatWeekly: false,
  parentId: null,
  length: null,
  isPaid: false,
  transactionId: null,
};
