import { pluck } from 'underscore';

const DEFAULT_PRICES = [
  {
    length: 30,
    displayLength: 30,
    active: true,
    prices: {
      1: 30,
      5: 30,
      10: 30,
    }
  },
  {
    length: 60,
    displayLength: 60,
    active: true,
    prices: {
      1: 50,
      5: 50,
      10: 50,
    }
  }
];

export default {
  computed: {
    minimumClassLength() {
      let min;

      if (this.$store.state.calendar.mode === 'change') {
        min = this.$store.state.basket.classToBeChanged.length;
      } else {
        min = Math.min(...pluck(this.getPrices(), 'length'));
      }

      return min;
    }
  },
  methods: {
    getPriceSettings() {
      const prices = this.getPrices();
      return prices.filter((el) => el.active);
    },
    getPrices() {
      let prices = DEFAULT_PRICES;

      if (this.$store.state.tutorPage.studentData && this.$store.state.tutorPage.studentPriceLists.length) {
        prices = this.$store.state.tutorPage.studentPriceLists; // student view - get student prices
      } else if (this.$store.state.tutorPage.publicSettings && this.$store.state.tutorPage.publicSettings.prices) {
        prices = this.$store.state.tutorPage.teacherPriceLists; // student view - teacher public prices
      } else if (this.$store.state.dashboard.userPublicSettings && this.$store.state.dashboard.userPublicSettings.prices) {
        prices = this.$store.state.dashboard.userPublicSettings.prices; // dashboard view only
      }

      return prices;
    },
    getDisplayLength(length) {
      const priceSetting = this.getPriceSettings().find((price) => price.length === length);

      return priceSetting && priceSetting.displayLength || length;
    },
  },
};
