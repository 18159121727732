const state = {
  items: [],
  currentAddToBasketItem: null,
  isBasketAddItemModalActive: false,
  isBasketCheckoutModalActive: false,
  isChangeItemDateModalActive: false,
  classToBeChanged: null,
  lastSelectedPriceIndex: null,
};

const getters = {};

const actions = {
  setChangeItemNewTimestamp({ commit, state }, data) {
    commit('SET_CLASS_TO_BE_CHANGED', {
      ...state.classToBeChanged,
      newStart: data.timestamp,
    });
  },
};

const mutations = {
  addItem(state, item) {
    state.items.push(item);
  },
  removeItem(state, index) {
    state.items.splice(index, 1);
  },
  clearBasket(state) {
    state.items = [];
  },
  setCurrentAddToBasketItem(state, item) {
    state.currentAddToBasketItem = item;
  },
  setBasketAddItemModalState(state, value) {
    state.isBasketAddItemModalActive = !!value;
  },
  setBasketCheckoutModalState(state, value) {
    state.isBasketCheckoutModalActive = !!value;
  },
  SET_IS_CHANGE_ITEM_DATE_MODAL_ACTIVE(state, value) {
    state.isChangeItemDateModalActive = value;
  },
  SET_CLASS_TO_BE_CHANGED(state, singleClass) {
    state.classToBeChanged = singleClass;
  },
  SET_LAST_SELECTED_PRICE_INDEX(state, length) {
    state.lastSelectedPriceIndex = length;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
