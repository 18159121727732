<template>
  <div class="CalendarPublicMatrixHeader" v-bind:class="{ 'CalendarPublicMatrixHeader --highlighted': isToday }">
    <b>{{ date }}</b><br />
    {{ name }}
  </div>
</template>

<script>
export default {
  name: 'CalendarPublicMatrixHeader',
  props: {
    isToday: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
    },
    name: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../common/styles/_variables.scss";

  .CalendarPublicMatrixHeader {
    border: 1px solid #ccc;
    border-left: none;
    background-color: #f1f1f1;
    height: $calendar_public_header_height;
    padding: 10px;

    &.--highlighted {
      background-color: wheat;
    }
  }
</style>
