<template>
  <div class="CalendarPublicDay">
    <CalendarPublicHour v-for="hour in day.hours"
                        :hour="hour"
                        :booking-enabled="bookingEnabled"
                        :available-slots="availableSlots"
    />
  </div>
</template>

<script>
import CalendarPublicHour from './CalendarPublicHour.vue';

export default {
  name: 'CalendarPublicDay',
  components: {
    CalendarPublicHour,
  },
  props: {
    day: {
      type: Object,
    },
    bookingEnabled: {
      type: Boolean,
    },
    availableSlots: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">

</style>
