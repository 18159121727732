<template>
  <div class="calendar-private-hour" :class="{'--general-availability': isMarkedAsAvailable}">
    <template v-for="timeSlot in hour">
      <CalendarPrivateTimeSlotStartEvent
        v-if="currentWeekEvents[timeSlot.timestamp]"
        :student-id="currentWeekEvents[timeSlot.timestamp].studentId"
        :student-name="getStudentName(currentWeekEvents[timeSlot.timestamp])"
        :starting-hour="printHour(timeSlot.timestamp)"
        :time-slots-length="currentWeekEvents[timeSlot.timestamp].length/slotMinutesRange"
        :is-paid-booking="isExternalBooking(currentWeekEvents[timeSlot.timestamp])"
        :is-temporary-blocked="!!currentWeekEvents[timeSlot.timestamp].paymentExpirationTimestamp"
        :is-repeated-weekly="currentWeekEvents[timeSlot.timestamp].repeatWeekly"
        :is-paid="currentWeekEvents[timeSlot.timestamp].isPaid"
        :timestamp="timeSlot.timestamp"
        :transaction-id="currentWeekEvents[timeSlot.timestamp].transactionId"
      />
      <CalendarPrivateTimeSlot
        v-else
        :time-display="timeSlot.display"
        :timestamp="timeSlot.timestamp"
      />
    </template>
  </div>
</template>

<script>
import calendarHelpers from '../../../mixins/calendarHelpers';
import CalendarPrivateTimeSlot from './CalendarPrivateTimeSlot.vue';
import CalendarPrivateTimeSlotStartEvent from './CalendarPrivateTimeSlotStartEvent.vue';

export default {
  name: 'CalendarPrivateHour',
  mixins: [calendarHelpers],
  components: {
    CalendarPrivateTimeSlot,
    CalendarPrivateTimeSlotStartEvent,
  },
  props: {
    hour: {
      type: Array,
    },
    currentWeekEvents: {
      type: Object,
    },
    isMarkedAsAvailable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  $calendar_cell_height: 100px;

  .calendar-private-hour {
    display: flex;
    height: $calendar_cell_height;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    flex-direction: column;
    align-items: stretch;

    &.--general-availability {
      background-color: #7eda7e2e;
    }
  }
</style>
