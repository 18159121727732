export default class TransactionsApi {
  constructor(db, state) {
    this.db = db;
    this.state = state;
  }

  loadTransactions() {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('transactions').orderBy('time', 'desc')
      .limit(100)
      .get();
  }

  toggleTransactionPayment({ transactionId, isPaid }) {
    const data = {
      transactionId,
      isPaid,
    };

    return firebase.functions().httpsCallable('toggleTransactionPayment')(data);
  }
}
