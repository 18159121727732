import Api from '@/api/api';
import { EventDefaultShape } from '../dataShape';

const rootState = {
  isLoadingMyClasses: false,
  myClasses: null,
};

const getters = {};

const actions = {
  loadMyClasses({ commit }) {
    commit('SET_IS_LOADING_MY_CLASSES', true);

    Api.events.getStudentEvents()
      .then((querySnapshot) => {
        const myClasses = [];
        querySnapshot.forEach((doc) => {
          const singleClass = {
            id: doc.id,
            ...doc.data()
          };
          myClasses.push({ ...EventDefaultShape, ...singleClass });
        });

        myClasses.sort((a, b) => b.start - a.start);

        commit('LOAD_MY_CLASSES', myClasses);
      })
      .finally(() => {
        commit('SET_IS_LOADING_MY_CLASSES', false);
      });
  },
};

const mutations = {
  SET_IS_LOADING_MY_CLASSES(state, isLoading) {
    state.isLoadingMyClasses = isLoading;
  },
  LOAD_MY_CLASSES(state, myClasses) {
    state.myClasses = myClasses;
  },
  CHANGE_CLASS_DATE(state, { eventId, newStart }) {
    const myClasses = [...state.myClasses];
    const classToBeUpdated = myClasses.find((myClass) => myClass.id === eventId);
    classToBeUpdated.start = newStart;
  },
};

export default {
  namespaced: false,
  state: rootState,
  getters,
  actions,
  mutations,
};
