import Api from '@/api/api.js';

const state = {
  readyToBookStartingSlotId: null,
  slotMinutesRange: 15,
  availableSlots: {},
  bookedSlots: {},
  editingSlotId: null,
  vacations: [],
  isLoadingBookedSlots: false,
  mode: 'booking',
};

const getters = {};

const actions = {
  addVacation({ commit }, { start, end, fullDay }) {
    const vacation = {
      start,
      end,
      fullDay,
    };

    Api.events.saveVacation(vacation);
    commit('addVacation', vacation);
  },
  removeVacation({ commit }, start) {
    Api.events.deleteVacation(start);
    commit('removeVacation', start);
  },
  loadBookedSlots({ commit }, { teacherId, timestampFrom, timestampTo }) {
    commit('setIsLoadingBookedSlots', true);

    Api.getBookedSlots({
      teacherId,
      timestampFrom,
      timestampTo,
    })
      .then((response) => {
        commit('setBookedSlots', response.data);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(window.location.reload, 5000);
      })
      .finally(() => commit('setIsLoadingBookedSlots', false));
  },
};

const mutations = {
  setReadyToBookStartingSlotId(state, value) {
    state.readyToBookStartingSlotId = value;
  },
  setAvailableSlots(state, value) {
    state.availableSlots = value;
  },
  setBookedSlots(state, bookedSlots) {
    state.bookedSlots = {
      ...state.bookedSlots,
      ...bookedSlots,
    };
  },
  setIsLoadingBookedSlots(state, isLoading) {
    state.isLoadingBookedSlots = isLoading;
  },
  setEditingSlot(state, slotId) {
    state.editingSlotId = slotId;
  },
  removeVacation(state, start) {
    state.vacations = state.vacations.filter((vacation) => vacation.start !== start);
  },
  addVacation(state, settings) {
    state.vacations.push({
      start: settings.start,
      end: settings.end,
      fullDay: settings.fullDay,
    });
  },
  setVacations(state, vacations) {
    state.vacations = vacations;
  },
  SET_MODE(state, mode) {
    state.mode = mode;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
