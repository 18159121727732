<template>
  <div class="CalendarPublicTimeSlotBookingEnabled"
       :class="{
                 'available': isAvailable,
                 'booked': isBooked,
                 'inBasket': isInBasket,
                 }"
       :id="timestamp"
       @mouseenter="onMouseEnterSlot"
       @mouseleave="onMouseLeaveSlot"
  >
    <div v-if="readyToBookStartingSlotId === timestamp && !basketItems[timestamp] && !isNearFuture(timestamp)"
         @click="onBookingSlotClicked(readyToBookStartingSlotId)"
         class="CalendarPublicTimeSlotBookingEnabled__readyToBookStartingSlot"
         v-bind:style="{
                  height: 100 + '%'
                 }"

    >
      <span>{{ timeDisplay }}</span>
    </div>
    <div v-else-if="readyToBookStartingSlotId === timestamp" class="CalendarPublicTimeSlotBookingEnabled__readyToBookStartingSlot">
      Niedostępne
    </div>
  </div>
</template>

<script>
import calendarHelpers from '@/common/mixins/calendarHelpers';
import priceHelpers from '@/common/mixins/priceHelpers';
import basketHelpers from '@/common/mixins/basketHelpers';

export default {
  name: 'CalendarPublicTimeSlotBookingEnabled',
  mixins: [calendarHelpers, priceHelpers, basketHelpers],
  props: {
    isAvailable: {
      type: Boolean,
    },
    isBooked: {
      type: Boolean,
    },
    isInBasket: {
      type: Boolean,
    },
    timestamp: {
      type: Number,
    },
    availableSlots: {
      type: Object,
    },
    timeDisplay: {
      type: String,
    },
    bookingText: {
      type: String,
      default: 'Kliknij, aby zarezerwować'
    },
  },
  computed: {
    readyToBookStartingSlotId() {
      return this.$store.state.calendar.readyToBookStartingSlotId;
    },
  },
  methods: {
    onMouseEnterSlot() {
      const findReadySlot = this.findReadySlot(this.minimumClassLength, this.timestamp, this.slotMinutesRange);

      if (findReadySlot) {
        this.$store.commit('setReadyToBookStartingSlotId', findReadySlot);
      }
    },

    onMouseLeaveSlot() {
      this.$store.commit('setReadyToBookStartingSlotId', null);
    },

    onBookingSlotClicked(slot) {
      if (this.calendarMode === 'change') {
        this.isChangeItemDateModalActive = true;
        this.$store.dispatch('setChangeItemNewTimestamp', {
          timestamp: slot,
        });
      } else {
        this.isBasketAddItemModalActive = true;
        this.$store.commit('setCurrentAddToBasketItem', {
          timestamp: slot,
          length: this.minimumClassLength,
          prices: this.getAvailablePrices(slot),
        });
      }
    },

    getAvailablePrices(timestamp) {
      const availablePrices = [];

      this.getPrices().map((price) => {
        if (this.findReadySlot(price.length, timestamp, this.slotMinutesRange)) {
          availablePrices.push(price);
        }
      });

      return availablePrices;
    },

    findReadySlot(classLength, timestamp, slotMinutesRange) {
      const slotsNumber = classLength / slotMinutesRange;
      const timestampIncrementalValue = slotMinutesRange * 60 * 1000;
      const isSlotAvailable = (timestamp) => this.availableSlots[timestamp] && !this.bookedSlots[timestamp] && !this.basketItems[timestamp];
      const isInPast = (timestamp) => this.moment().add(1, 'd').startOf('day').valueOf() > timestamp;

      let readySlotTimestamp = null;
      let slotPosition = 1;
      let successfullIterations = 0;
      let requiredSlotsNumber = slotsNumber - 1;

      if (isInPast(timestamp) || !isSlotAvailable(timestamp)) {
        return null;
      }

      while (isSlotAvailable(timestamp + timestampIncrementalValue * slotPosition) && requiredSlotsNumber > successfullIterations) {
        successfullIterations += 1;
        slotPosition += 1;
      }

      if (requiredSlotsNumber === successfullIterations) {
        readySlotTimestamp = timestamp;
      } else if (false && requiredSlotsNumber > successfullIterations) { // TODO: currently only exact correct hovering
        requiredSlotsNumber -= successfullIterations;
        successfullIterations = 0;
        slotPosition = 1;

        while (isSlotAvailable(timestamp - timestampIncrementalValue * slotPosition) && requiredSlotsNumber > successfullIterations) {
          successfullIterations += 1;
          slotPosition += 1;
        }

        if (requiredSlotsNumber === successfullIterations) {
          readySlotTimestamp = timestamp - timestampIncrementalValue * successfullIterations;
        }
      }

      return readySlotTimestamp;
    },
  },
};
</script>

<style lang="scss">
  .CalendarPublicTimeSlotBookingEnabled {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    visibility: hidden;

    span {
      visibility: hidden;
    }

    &.available {
      visibility: visible;
      background-color: #38b559;

      &:hover {
        span {
          visibility: visible;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }

    &.available.booked {
      background-color: #F44336;
      background-image: repeating-linear-gradient(45deg,transparent,transparent 10px,hsla(0,0%,100%,.8) 0,hsla(0,0%,100%,.8) 15px);

      span {
        visibility: hidden;
      }
    }

    &.available.inBasket {
      background-color: #f49a28;
      background-image: repeating-linear-gradient(45deg,transparent,transparent 10px,hsla(0,0%,100%,.8) 0,hsla(0,0%,100%,.8) 15px);

      span {
        visibility: hidden;
      }
    }

    &__readyToBookStartingSlot {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      background-color: #ff9800;
      z-index: 5;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 0px 6px 0px rgba(0,0,0,0.75);
    }
  }
</style>
