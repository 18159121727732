<template>
<div class="CalendarMatrix" :class="{ 'PublicMode': publicMode }">
  <div class="CalendarMatrix__column CalendarMatrix__column-legend">
    <div class="CalendarMatrix__legend-top"></div>
    <div class="CalendarMatrix__legend" v-for="hour in hours">
      {{ hour }}:00
    </div>
  </div>
  <div class="CalendarMatrix__columns_scroll">
    <div class="CalendarMatrix__columns_wrapper">
      <div class="CalendarMatrix__column" v-for="day in days" v-bind:class="{ '--past': day.isPast }">
        <CalendarPrivateMatrixHeader v-if="!publicMode"
                               :date="day.date"
                               :name="day.name"
                               :isToday="day.isToday"
                               :isPast="day.isPast"
                               :timestamp="day.timestamp"
        />
        <CalendarPublicMatrixHeader v-else
                              :date="day.date"
                              :name="day.name"
                              :isToday="day.isToday"
        />

        <CalendarPrivateDay v-if="!publicMode"
                           :day="day"
                           :current-week-events="currentWeekEvents"
                           :slot-minutes-range="slotMinutesRange"
        />
        <CalendarPublicDay v-else
                           :day="day"
                           :available-slots="availableSlots"
                           :booking-enabled="bookingEnabled"
        />
      </div>
      <div v-if="publicMode && isNotAvailable" class="CalendarMatrix__no_available">
        <div class="message">
          Brak wolnych miejsc w tym tygodniu. <br />Przejdź do <a href="#" @click.prevent="onNextWeekClicked()">następnego tygodnia.</a>
        </div>
      </div>
    </div>
  </div>
  <slot />
</div>
</template>

<script>
import calendarHelpers from '../../mixins/calendarHelpers';
import CalendarPrivateDay from './Private/CalendarPrivateDay.vue';
import CalendarPublicDay from './Public/CalendarPublicDay.vue';
import CalendarPrivateMatrixHeader from './Private/CalendarPrivateMatrixHeader.vue';
import CalendarPublicMatrixHeader from './Public/CalendarPublicMatrixHeader.vue';

export default {
  name: 'CalendarMatrix',
  mixins: [calendarHelpers],
  components: {
    CalendarPrivateDay,
    CalendarPublicDay,
    CalendarPrivateMatrixHeader,
    CalendarPublicMatrixHeader,
  },
  props: {
    currentWeekEvents: {
      type: Object,
    },
    currentWeek: {
      type: Object,
    },
    bookingEnabled: {
      type: Boolean,
      default: false,
    },
    publicMode: {
      type: Boolean,
      default: false,
    },
    isNotAvailable: {
      type: Boolean,
      default: false,
    },
    availableSlots: {
      type: Object,
    },
    hoursRange: {
      default() {
        return {
          start: 6,
          end: 21,
        };
      },
    },
  },
  data() {
    return {
      readyToBookStartingSlotId: null,
    };
  },
  mounted() {
    if (this.publicMode) {
      $(this.$el).find('[data-content]').popup({
        position: 'left center'
      });
    }
  },
  computed: {
    hours() {
      const hours = [];

      for (let i = this.hoursRange.start; i <= this.hoursRange.end; i++) {
        hours.push(i);
      }

      return hours;
    },
    days() {
      const startOfWeek = this.moment(this.currentWeek.start);
      const days = [];

      for (let i = 0; i < 7; i++) {
        const start = startOfWeek.clone();
        const currentDay = start.add(i, 'd');
        const date = currentDay.format('Do MMM');
        const name = currentDay.format('dddd');
        const hours = this.getHours(currentDay.clone());
        const isToday = currentDay.isSame(this.moment(), 'day');
        const isPast = currentDay.diff(this.moment(), 'days') < 0 || isToday;
        const weekDayNumber = currentDay.isoWeekday();

        days.push({
          date,
          name,
          hours,
          isToday,
          isPast,
          weekDayNumber,
          timestamp: currentDay.valueOf(),
        });
      }

      return days;
    },
  },
  methods: {
    getHours(day) {
      const hours = [];
      const startHour = day.add(this.hoursRange.start, 'h');
      const diff = this.hoursRange.end - this.hoursRange.start;
      const slotsPerHour = 60 / this.slotMinutesRange;

      for (let i = 0; i <= diff; i++) {
        const currentHour = startHour.clone().add(i, 'h');
        const slots = [];

        for (let j = 0; j < slotsPerHour; j++) {
          const slotTime = currentHour.clone().add(this.slotMinutesRange * j, 'm');

          slots.push({
            timestamp: slotTime.valueOf(),
            display: slotTime.format('H:mm'),
            index: +slotTime.format('H')
          });
        }

        hours.push(slots);
      }

      return hours;
    },
    onNextWeekClicked() {
      document.querySelector('.CalendarHeader__button--right button').dispatchEvent(new MouseEvent('click'));
    },

  },
};
</script>

<style lang="scss">
@import "../../../common/styles/_variables.scss";

.CalendarMatrix {
  display: flex;
  position: relative;

  &__no_available {
    position: absolute;
    height: calc(100% - 60px);
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #000000ab;
    font-size: 2em;
    line-height: 2em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .message {
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

}

.CalendarMatrix__columns_wrapper {
  display: flex;
  min-width: 650px;
}

.CalendarMatrix__columns_scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.CalendarMatrix__column-legend {
  max-width: 55px;
}

.CalendarMatrix__column {
  background-color: #fdfdfd;
  flex-grow: 2;
  flex-basis: 0;
  min-height: 200px;

  &.--past {
    background-color: rgba(185, 185, 185, .3);

    .CalendarPublicDay {
      opacity: .5;
    }
  }
}

.PublicMode {
  .CalendarMatrix__legend {
    min-height: $calendar_public_row_height;
  }

  .CalendarMatrix__legend-top {
    height: $calendar_public_header_height;
  }
}

.CalendarMatrix__legend {
  display: flex;
  min-height: $calendar_private_row_height;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #eee;
  flex-direction: column;
  align-items: stretch;
  text-align: right;
  box-sizing: border-box;
  padding: 10px;
}

.CalendarMatrix__legend-top {
  height: $calendar_private_header_height;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
</style>
