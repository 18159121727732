<template>
  <div class="ui modal" id="modal-login">
    <i class="close icon" @click="onClose"></i>
    <div class="header">
      Logowanie
    </div>
    <template v-if="!loggedInNoAccess">
      <div class="content">
        <form class="ui form">
          <div class="field">
            <label>Email</label>
            <input type="text" name="email" placeholder="Adres e-mail" v-model="loginForm.email">
          </div>
          <div class="field" v-show="!resetPasswordMode">
            <label>Hasło</label>
            <input type="password" name="password" placeholder="Hasło" v-model="loginForm.password">
          </div>
          <div class="ui error message"></div>
        </form>
        <div v-if="resetPasswordSuccessMsg" class="ui success message">
          {{resetPasswordSuccessMsg}}
        </div>
        <div v-else-if="resetPasswordErrorMsg" class="ui error message">
          {{resetPasswordErrorMsg}}
        </div>
        <a v-if="resetPasswordMode" href="#" @click="setPasswordResetMode(false)">Powrót do logowania</a>
        <a v-else="resetPasswordMode" href="#" @click="setPasswordResetMode(true)">Zapomniałem hasła</a>
      </div>
      <div class="actions">
        <div class="ui black button" @click="onClose">
          Zamknij
        </div>
        <div v-if="resetPasswordMode" class="ui positive right labeled icon button" :class="{ loading: isLoading }" @click="passwordReset">
          Wyślij email z linkiem do resetu hasła
          <i class="mail icon"></i>
        </div>
        <div v-else class="ui positive right labeled icon button" :class="{ loading: isLoading }" @click="onFormSubmit">
          Zaloguj się
          <i class="checkmark icon"></i>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content">
        <div class="ui message error">
          <div class="header">
            Brak dostępu
          </div>
          <p>Aktualnie zalogowany użytkownik nie ma dostępu do strefy ucznia. Proszę o kontakt z nauczycielem lub proszę zalogować się na konto przypisane danej stronie.</p>
        </div>
      </div>
      <div class="actions">
        <div class="ui positive right labeled icon button" @click="signOut()">
          Wyloguj się
          <i class="checkmark icon"></i>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import api from '@/api/api';

export default {
  props: {
    entry: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loginForm: {
        email: null,
        password: null,
      },
      isLoading: false,
      isListeningToAuthChange: false,
      loggedInNoAccess: false,
      resetPasswordMode: false,
      resetPasswordSuccessMsg: null,
      resetPasswordErrorMsg: null,
    };
  },
  computed: {
    isModalOpen() {
      return this.$store.state.authentication.isLoginModalOpen;
    },
    isTutorPage() {
      return this.entry === 'index';
    },
    isDashboardPage() {
      return this.entry === 'dashboard';
    },
    tutorPageTeacherId() {
      return this.$store.state.tutorPage.teacherId;
    },
  },
  watch: {
    isModalOpen(newVal) {
      this.$modal.modal(newVal ? 'show' : 'hide');
    },
    tutorPageTeacherId(newVal) {
      if (!this.isListeningToAuthChange && newVal) {
        this.listenToAuthChange();
      }
    },
  },
  created() {
    if (this.isDashboardPage) {
      this.listenToAuthChange();
    } else if (!this.isListeningToAuthChange && this.tutorPageTeacherId) {
      this.listenToAuthChange();
    }
  },
  mounted() {
    const self = this;
    this.$modal = $(this.$el);
    this.$form = $(this.$el).find('.ui.form');

    this.$modal.modal({
      closable: false,
      onApprove() {
        return false;
      },
    });

    this.$form.form({
      on: 'submit',
      fields: {
        email: {
          identifier: 'email',
          rules: [
            { type: 'empty', prompt: 'Proszę wpisać email' },
            { type: 'email', prompt: 'Proszę wpisać poprawny adres e-mail' },
          ]
        },
        password: {
          identifier: 'password',
          rules: [
            { type: 'empty', prompt: 'Proszę wpisać hasło' },
          ]
        },
      },
      onSuccess: (e) => {
        if (e) e.preventDefault();
        self.isLoading = true;
        self.signIn();
      },
    });
  },
  methods: {
    listenToAuthChange() {
      const self = this;

      api.onAuthStateChanged((user) => {
        if (user) {
          if (self.isTutorPage) {
            self.getStudentData(user.uid);
          } else if (self.isDashboardPage) {
            self.getTeacherData(user.uid);
            self.$store.commit('SET_IS_LOGIN_MODAL_OPEN', false);
          }
        } else {
          self.$store.dispatch('userSignedOut');

          if (self.isDashboardPage) {
            self.$store.commit('SET_IS_LOGIN_MODAL_OPEN', true);
          }
        }
      });

      this.isListeningToAuthChange = true;
    },
    onFormSubmit() {
      this.$form.form('validate form');
    },
    addFormError(errorMsg) {
      this.$form.form('add errors', [errorMsg]);
    },
    setPasswordResetMode(val) {
      this.resetPasswordSuccessMsg = null;
      this.resetPasswordErrorMsg = null;
      this.$form.form('reset');
      $('form .ui.error.message').empty();

      this.resetPasswordMode = val;
    },
    passwordReset() {
      const self = this;
      this.isLoading = true;

      api.sendPasswordResetEmail(this.loginForm.email || 'invalid')
        .then(() => {
          self.resetPasswordSuccessMsg = 'Email z linkiem do resetu hasła został wysłany na podany adres e-mail.';
        })
        .catch(() => {
          self.resetPasswordErrorMsg = 'Email nie został wysłany. Sprawdź poprawność adresu email.';
        })
        .finally(() => {
          self.isLoading = false;
        });
    },
    signIn() {
      api.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password)
        .catch(() => {
          this.addFormError('Niepoprawne dane logowania');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    signOut() {
      api.signOut().then(() => {
        this.loggedInNoAccess = false;
      });
      this.$store.commit('SET_IS_LOGIN_MODAL_OPEN', false);
    },
    onClose() {
      if (this.isDashboardPage) {
        window.location.href = '/';
      } else {
        this.$store.commit('SET_IS_LOGIN_MODAL_OPEN', false);
      }

      this.$form.form('reset clear');
    },
    getStudentData(studentId) {
      api.students.getStudentData({
        teacherId: this.tutorPageTeacherId,
        studentId,
      })
        .then((doc) => {
          if (doc.exists) {
            this.$store.dispatch('studentSignedIn', doc);
            this.$store.commit('SET_IS_LOGIN_MODAL_OPEN', false);
          } else {
            this.$store.dispatch('notValidStudentSignedIn');
            this.addFormError('Brak dostępu. Proszę skontaktować się z nauczycielem.');
            this.loggedInNoAccess = true;
          }
        })
        .catch((error) => {
          console.log('something went wrong', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getTeacherData(teacherId) {
      api.tutors.getTeacher(teacherId)
        .then((doc) => {
          if (doc.exists) {
            this.$store.dispatch('teacherSignedIn', doc);
          } else {
            this.$store.dispatch('notValidTeacherSignedIn', doc);
            this.$store.commit('SET_IS_LOGIN_MODAL_OPEN', true);
          }
        })
        .catch((e) => {
          console.log('something went wrong', e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
