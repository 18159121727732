/* eslint-disable class-methods-use-this */
export default class StudentsApi {
  constructor(db, state) {
    this.db = db;
    this.state = state;
  }

  saveStudent({ student, priceLists }) {
    const teacherId = this.state.authentication.authenticatedTeacherId;
    let studentId;

    const existingEmailSnapshot = this.db.collection('teachers').doc(teacherId).collection('students').where('email', '==', student.email).get();

    return existingEmailSnapshot
      .then((snapshot) => {
        if (!snapshot.empty) {
          throw 'Posiadasz ucznia o takim emailu.';
        }
      })
      .then(() => this.db.collection('teachers').doc(teacherId).collection('students').add(student))
      .then((doc) => {
        return Promise.all(priceLists.map((priceList) => {
          studentId = doc.id;
          return this.db.collection(`teachers/${teacherId}/students/${studentId}/priceLists`).doc(priceList.id).set(priceList);
        }));
      })
      .then(() => {
        return studentId;
      });
  }

  editStudent(student, studentId) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('students').doc(studentId)
      .update(student);
  }

  loadStudents() {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('students').get();
  }

  saveNote(studentId, note) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('students').doc(studentId)
      .collection('notes')
      .add(note);
  }

  loadNotes(studentId) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('students').doc(studentId)
      .collection('notes')
      .get();
  }

  deleteStudent(studentId) {
    return this.db.collection('teachers').doc(this.state.authentication.authenticatedTeacherId).collection('students').doc(studentId)
      .delete();
  }

  inviteStudent(studentId) {
    const data = {
      studentId
    };

    return firebase.functions().httpsCallable('inviteStudent')(data);
  }

  getInvitationDetails({ teacherId, registrationKey }) {
    const data = {
      teacherId,
      registrationKey,
    };

    return firebase.functions().httpsCallable('getInvitationDetails')(data);
  }

  createStudentAccount({ password, teacherId, registrationKey }) {
    return firebase.functions().httpsCallable('createStudentAccount')({ password, teacherId, registrationKey });
  }

  getStudentData({ teacherId, studentId }) {
    return this.db.collection(`teachers/${teacherId}/students`).doc(studentId).get();
  }

  loadPriceLists(studentId) {
    const teacherId = this.state.authentication.authenticatedTeacherId || this.state.tutorPage.teacherId;

    return this.db.collection(`teachers/${teacherId}/students/${studentId}/priceLists`).get();
  }

  updatePriceList({ priceList, priceListId, studentId }) {
    const teacherId = this.state.authentication.authenticatedTeacherId;

    return this.db.collection(`teachers/${teacherId}/students/${studentId}/priceLists`).doc(priceListId)
      .update(priceList);
  }

  addPriceList({ priceList, studentId }) {
    const teacherId = this.state.authentication.authenticatedTeacherId;

    return this.db.collection(`teachers/${teacherId}/students/${studentId}/priceLists`).add(priceList);
  }

  deletePriceList({ priceListId, studentId }) {
    const teacherId = this.state.authentication.authenticatedTeacherId;
    return this.db.collection(`teachers/${teacherId}/students/${studentId}/priceLists`).doc(priceListId)
      .delete();
  }
}
