<template>
  <div class="CalendarPrivateMatrixHeader" :class="{ '--highlighted': isToday, '--not-available': !isAvailable, }">
    <span class="CalendarPrivateMatrixHeader__date"><b>{{ date }}</b><br /></span>
    <span class="CalendarPrivateMatrixHeader__name">{{ name }}</span>
    <div class="CalendarPrivateMatrixHeader__availability" v-if="!isPast">
      <div class="ui checked checkbox">
        <input type="checkbox" v-model="isAvailable">
        <label>Aktywne</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarPrivateMatrixHeader',
  props: {
    isToday: {
      type: Boolean,
      default: false,
    },
    isPast: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
    },
    name: {
      type: String,
    },
    timestamp: {
      type: Number,
    },
  },
  computed: {
    isAvailable: {
      get() {
        return !this.$store.state.calendar.vacations.find((vacation) => vacation.fullDay && vacation.start === this.timestamp);
      },
      set(updatedIsAvailable) {
        if (updatedIsAvailable) {
          this.$store.dispatch('removeVacation', this.timestamp);
        } else {
          this.$store.dispatch(
            'addVacation',
            {
              start: this.timestamp,
              end: this.moment(this.timestamp).endOf('day').valueOf(),
              fullDay: true,
            }
          );
        }
      },
    },
  },
};
</script>

<style lang="scss">
  .CalendarPrivateMatrixHeader {
    border: 1px solid #ccc;
    border-left: none;
    background-color: #f1f1f1;
    height: 100px;
    padding: 10px;

    &.--highlighted {
      background-color: wheat;
    }

    &.--not-available {
      opacity: .5;

      span {
        text-decoration: line-through;
      }
    }

    &__availability {
      padding: 15px 0;
    }
  }
</style>
