import Api from '@/api/api';
import Vue from 'vue';
import {EventDefaultShape, PriceListDefaultShape, StudentDefaultShape} from '../dataShape';

const rootState = {
  uid: null,
  isTeacherVerified: false,
  isTeacherVerificationPending: true,
  userSettings: {
    availabilityTimes: {},
    stripeChargesEnabled: false,
  },
  userPublicSettings: {

  },
  students: [],
  studentClasses: null,
  studentPriceLists: [],
  pendingRoute: null,
  loadingUser: true,
  loadingStudents: true,
  loadingPriceLists: true,
  priceLists: [],
  teacherId: null,
  transactions: [],
  isLoadingTransactions: false,
  isLoadingPublicSettings: false,
  isLoadingStudentClasses: false,
  isLoadingStudentPriceLists: false,
  isSavingPublicSettings: false,
  isSavingSettings: false,
  isSavingStudent: false,
  isSavingTransferSettings: false,
};

const getters = {};

const actions = {
  saveAvailabilityTimes({ dispatch, commit }, availabilityTimes) {
    Api.tutors.updateSettings({ availabilityTimes })
      .then(() => {
        commit('UPDATE_USER_AVAILABILITY', availabilityTimes);
        dispatch('addNotification', { type: 'success', msg: 'Ustawienia zostały zapisane.' });
      })
      .catch((error) => {
        console.log(error);
        dispatch('addNotification', { type: 'failure', msg: 'Wystąpił błąd podczas zapisywania ustawień.' });
      });
  },
  loadUserSettings({ commit }, uid) {
    return new Promise((resolve, reject) => {
      Api.getUserSettings(uid)
        .then((doc) => {
          const userSettings = doc.data();
          commit('LOAD_USER_SETTINGS', Object.assign(rootState.userSettings, userSettings));
          commit('SET_LOADING_USER', false);
          resolve(userSettings);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  loadPublicSettings({ commit }) {
    commit('SET_IS_LOADING_PUBLIC_SETTINGS', true);

    Api.tutors.getPublicSettings()
      .then((doc) => {
        const publicSettings = doc.data();
        commit('SET_PUBLIC_SETTINGS', publicSettings);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => commit('SET_IS_LOADING_PUBLIC_SETTINGS', false));
  },

  updatePublicSettings({ commit, dispatch }, publicSettings) {
    commit('SET_IS_SAVING_PUBLIC_SETTINGS', true);

    Api.tutors.updatePublicSettings(publicSettings)
      .then(() => {
        commit('UPDATE_PUBLIC_SETTINGS', publicSettings);
        dispatch('addNotification', { type: 'success', msg: 'Ustawienia publiczne zostały zapisane.' });
      })
      .catch((error) => {
        console.log(error);
        dispatch('addNotification', { type: 'failure', msg: 'Wystąpił błąd podczas zapisywania ustawień publicznych.' });
      })
      .finally(() => {
        commit('SET_IS_SAVING_PUBLIC_SETTINGS', false);
      });
  },

  updateTransferSettings({ commit, dispatch }, publicSettings) {
    commit('SET_IS_SAVING_TRANSFER_SETTINGS', true);

    Api.tutors.updatePublicSettings(publicSettings)
      .then(() => {
        commit('UPDATE_PUBLIC_SETTINGS', publicSettings);
        dispatch('addNotification', { type: 'success', msg: 'Dane do przelewu bankowego zostały zapisane.' });
      })
      .catch((error) => {
        console.log(error);
        dispatch('addNotification', { type: 'failure', msg: 'Wystąpił błąd podczas zapisywania danych do przelewu bankowego.' });
      })
      .finally(() => {
        commit('SET_IS_SAVING_TRANSFER_SETTINGS', false);
      });
  },

  updateUserSettings({ commit, dispatch }, settings) {
    Api.tutors.updateSettings(settings)
      .then(() => {
        commit('UPDATE_USER_SETTINGS', settings);
        dispatch('addNotification', { type: 'success', msg: 'Ustawienia prywatne zostały zapisane.' });
      })
      .catch((error) => {
        console.log(error);
        dispatch('addNotification', { type: 'failure', msg: 'Wystąpił błąd podczas zapisywania ustawień prywatnych.' });
      });
  },

  inviteStudent({ commit }, studentId) {
    commit('SET_SENDING_INVITATION_PENDING', studentId);

    Api.students.inviteStudent(studentId)
      .then((res) => {
        commit('SET_SENDING_INVITATION_SUCCESS', { studentId, registrationKey: res.data });
      })
      .catch((error) => {
        commit('SET_SENDING_INVITATION_FAILURE', studentId);
        console.log(error);
      });
  },

  addStudent({ commit, dispatch }, { student, priceLists }) {
    commit('SET_IS_SAVING_STUDENT', true);

    if (!student.firstName) return;

    return Api.students.saveStudent({ student, priceLists })
      .then((studentId) => {
        student.id = studentId;
        commit('ADD_STUDENT', student);
        dispatch('addNotification', { type: 'success', msg: 'Uczeń został dodany.' });
      })
      .catch((error) => {
        dispatch('addNotification', { type: 'failure', msg: `Wystąpił błąd podczas dodawania ucznia: ${error}` });
      })
      .finally(() => {
        commit('SET_IS_SAVING_STUDENT', false);
      });
  },

  editStudent({ commit, dispatch }, { studentChange, studentId }) {
    commit('SET_IS_SAVING_STUDENT', true);

    Api.students.editStudent(studentChange, studentId)
      .then((doc) => {
        commit('EDIT_STUDENT', { studentChange, studentId });
        dispatch('addNotification', { type: 'success', msg: 'Zmiany zostały zapisane.' });
      })
      .catch((error) => {
        console.log(error);
        dispatch('addNotification', { type: 'failure', msg: 'Wystąpił błąd podczas zapisywania danych studenta.' });
      })
      .finally(() => {
        commit('SET_IS_SAVING_STUDENT', false);
      });
  },

  deleteStudent({ commit }, studentId) {
    Api.students.deleteStudent(studentId)
      .then(() => {
        commit('DELETE_STUDENT', studentId);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  loadStudents({ commit }) {
    Api.students.loadStudents()
      .then((querySnapshot) => {
        const students = [];
        querySnapshot.forEach((doc) => {
          const student = { ...StudentDefaultShape, ...doc.data(), id: doc.id };
          students.push(student);
        });
        commit('SET_STUDENTS', students);
        commit('SET_LOADING_STUDENTS', false);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  loadPriceLists({ commit }) {
    commit('SET_LOADING_PRICE_LISTS', true);

    Api.priceLists.loadPriceLists()
      .then((querySnapshot) => {
        const priceLists = [];
        querySnapshot.forEach((doc) => {
          const priceList = { ...PriceListDefaultShape, ...doc.data(), id: doc.id };
          priceLists.push(priceList);
        });
        commit('SET_PRICE_LISTS', priceLists);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit('SET_LOADING_PRICE_LISTS', false);
      });
  },

  deletePriceList({ commit, dispatch }, priceListId) {
    return Api.priceLists.deletePriceList(priceListId)
      .then(() => {
        commit('DELETE_PRICE_LIST', priceListId)
        dispatch('addNotification', {type: 'success', msg: 'Cennik został usunięty'});
      })
      .catch(() => {
        dispatch('addNotification', {
          type: 'failure',
          msg: 'Wystąpił błąd podczas usuwania cennika.'
        });
      });
  },

  deleteStudentPriceList({ commit, dispatch }, { priceListId, studentId }) {
    return Api.students.deletePriceList({ priceListId, studentId })
      .then(() => {
        commit('DELETE_STUDENT_PRICE_LIST', priceListId)
        dispatch('addNotification', {type: 'success', msg: 'Cennik został usunięty'});
      })
      .catch(() => {
        dispatch('addNotification', {
          type: 'failure',
          msg: 'Wystąpił błąd podczas usuwania cennika.'
        });
      });
  },

  loadTransactions({ commit }) {
    commit('SET_IS_LOADING_TRANSACTIONS', true);

    Api.transactions.loadTransactions()
      .then((querySnapshot) => {
        const transactions = [];

        querySnapshot.forEach((doc) => {
          const transaction = {
            id: doc.id,
            isUpdatingPaymentStatus: false,
            ...doc.data()
          };
          transactions.push(transaction);
        });

        commit('SET_TRANSACTIONS', transactions);
      })
      .catch((error) => {
        console.log(error);
        alert('There was an error');
        setTimeout(window.location.reload, 5000);
      })
      .finally(() => commit('SET_IS_LOADING_TRANSACTIONS', false));
  },

  toggleTransactionPayment({ commit }, { transactionId, isPaid }) {
    commit('SET_TRANSACTION_PAYMENT_UPDATE_PENDING', transactionId);

    Api.transactions.toggleTransactionPayment({ transactionId, isPaid })
      .then(() => {
        commit('SET_TRANSACTION_PAYMENT_STATUS', { transactionId, isPaid });
      })
      .finally(() => {
        commit('SET_TRANSACTION_PAYMENT_UPDATE_DONE', transactionId);
      });
  },

  loadStudentClasses({ commit }, studentId) {
    commit('SET_STUDENT_CLASSES', null);
    commit('SET_IS_LOADING_STUDENT_CLASSES', true);

    Api.events.getStudentEvents(studentId)
      .then((querySnapshot) => {
        const studentClasses = [];
        querySnapshot.forEach((doc) => {
          const singleClass = {
            id: doc.id,
            ...doc.data()
          };
          studentClasses.push({ ...EventDefaultShape, ...singleClass });
        });

        studentClasses.sort((a, b) => b.start - a.start);

        commit('SET_STUDENT_CLASSES', studentClasses);
      })
      .finally(() => {
        commit('SET_IS_LOADING_STUDENT_CLASSES', false);
      });
  },

  loadStudentPriceLists({ commit }, studentId) {
    commit('SET_STUDENT_PRICE_LISTS', []);
    commit('SET_IS_LOADING_STUDENT_PRICE_LISTS', true);

    Api.students.loadPriceLists(studentId)
      .then((querySnapshot) => {
        const priceLists = [];
        querySnapshot.forEach((doc) => {
          const priceList = {
            id: doc.id,
            ...doc.data()
          };
          priceLists.push({ ...priceList });
        });

        commit('SET_STUDENT_PRICE_LISTS', priceLists);
      })
      .finally(() => {
        commit('SET_IS_LOADING_STUDENT_PRICE_LISTS', false);
      });
  },
};

const mutations = {
  UPDATE_USER_SETTINGS(state, data) {
    state.userSettings = { ...state.userSettings, ...data };
  },
  LOAD_USER_SETTINGS(state, data) {
    state.userSettings = data;
  },
  ADD_USER_AVAILABILITY(state, id) {
    state.userSettings.availabilityTimes[id] = 1;
  },
  UPDATE_USER_AVAILABILITY(state, availabilityTimes) {
    state.userSettings.availabilityTimes = availabilityTimes;
  },
  ADD_STUDENT(state, student) {
    state.students.unshift(student);
    state.students.sort((a, b) => {
      if (a.firstName < b.firstName) { return -1; }
      if (a.firstName > b.firstName) { return 1; }
      return 0;
    });
  },
  EDIT_STUDENT(state, { studentChange, studentId }) {
    const index = state.students.findIndex((student) => student.id === studentId);
    const updatedStudent = { ...state.students[index], ...studentChange };
    Vue.set(state.students, index, updatedStudent);
  },
  DELETE_STUDENT(state, studentId) {
    const filteredStudents = state.students.filter((student) => student.id !== studentId);
    state.students = filteredStudents;
  },
  SET_STUDENTS(state, students) {
    students.sort((a, b) => a.firstName.localeCompare(b.firstName));
    state.students = students;
  },
  SET_LOADING_STUDENTS(state, value) {
    state.loadingStudents = value;
  },
  SET_LOADING_USER(state, value) {
    state.loadingUser = value;
  },
  DELETE_PRICE_LIST(state, priceListId) {
    state.priceLists = state.priceLists.filter((priceList) => priceList.id !== priceListId);
  },
  DELETE_STUDENT_PRICE_LIST(state, priceListId) {
    state.studentPriceLists = state.studentPriceLists.filter((priceList) => priceList.id !== priceListId);
  },
  SET_LOADING_PRICE_LISTS(state, value) {
    state.loadingPriceLists = value;
  },
  SET_PRICE_LISTS(state, value) {
    state.priceLists = value;
  },
  SET_PENDING_ROUTE(state, value) {
    state.pendingRoute = value;
  },
  SET_STUDENT_CLASSES(state, value) {
    state.studentClasses = value;
  },
  SET_STUDENT_PRICE_LISTS(state, value) {
    state.studentPriceLists = value;
  },
  SET_SENDING_INVITATION_PENDING(state, studentId) {
    const index = state.students.findIndex((student) => student.id === studentId);
    const student = state.students[index];
    const updatedStudent = {
      ...student,
      sendingInvitation: 'pending'
    };
    Vue.set(state.students, index, updatedStudent);
  },
  SET_SENDING_INVITATION_SUCCESS(state, { studentId, registrationKey }) {
    const index = state.students.findIndex((student) => student.id === studentId);
    const student = state.students[index];
    const updatedStudent = {
      ...student,
      sendingInvitation: 'success',
      registrationKey,
    };
    Vue.set(state.students, index, updatedStudent);
  },
  SET_SENDING_INVITATION_FAILURE(state, studentId) {
    const index = state.students.findIndex((student) => student.id === studentId);
    const student = state.students[index];
    const updatedStudent = {
      ...student,
      sendingInvitation: 'failure'
    };
    Vue.set(state.students, index, updatedStudent);
  },
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },
  SET_IS_LOADING_TRANSACTIONS(state, isLoading) {
    state.isLoadingTransactions = isLoading;
  },
  SET_IS_TEACHER_VERIFIED(state, isVerified) {
    state.isTeacherVerified = isVerified;
  },
  SET_IS_TEACHER_VERIFICATION_PENDING(state, isPending) {
    state.isTeacherVerificationPending = isPending;
  },
  SET_IS_LOADING_PUBLIC_SETTINGS(state, isLoading) {
    state.isLoadingPublicSettings = isLoading;
  },
  SET_IS_LOADING_STUDENT_CLASSES(state, isLoading) {
    state.isLoadingStudentClasses = isLoading;
  },
  SET_IS_LOADING_STUDENT_PRICE_LISTS(state, isLoading) {
    state.isLoadingStudentPriceLists = isLoading;
  },
  SET_PUBLIC_SETTINGS(state, publicSettings) {
    state.userPublicSettings = publicSettings || {};
  },
  UPDATE_PUBLIC_SETTINGS(state, publicSettings) {
    state.userPublicSettings = {
      ...state.userPublicSettings,
      ...publicSettings
    };
  },
  SET_IS_SAVING_PUBLIC_SETTINGS(state, isSaving) {
    state.isSavingPublicSettings = isSaving;
  },
  SET_IS_SAVING_TRANSFER_SETTINGS(state, isSaving) {
    state.isSavingTransferSettings = isSaving;
  },
  SET_IS_SAVING_STUDENT(state, isSaving) {
    state.isSavingStudent = isSaving;
  },
  SET_STRIPE_CHARGES_ENABLED(state, areChargesEnabled) {
    state.userSettings.stripeChargesEnabled = areChargesEnabled;
  },
  SET_TRANSACTION_PAYMENT_UPDATE_PENDING(state, transactionId) {
    const transaction = state.transactions.find((t) => t.id == transactionId);
    if (transaction) {
      transaction.isUpdatingPaymentStatus = true;
    }
  },
  SET_TRANSACTION_PAYMENT_UPDATE_DONE(state, transactionId) {
    const transaction = state.transactions.find((t) => t.id == transactionId);
    if (transaction) {
      transaction.isUpdatingPaymentStatus = false;
    }
  },
  SET_TRANSACTION_PAYMENT_STATUS(state, { transactionId, isPaid }) {
    const transaction = state.transactions.find((t) => t.id == transactionId);
    if (transaction) {
      transaction.status = isPaid ? 'bank transfer paid' : 'bank transfer';
    }
  }
};

export default {
  namespaced: false,
  state: rootState,
  getters,
  actions,
  mutations,
};
