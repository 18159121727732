<template>
  <div class="CalendarPublicTimeSlot"
       :class="{ 'available': isAvailable, 'booked': isBooked }"
       :id="timestamp"
  >
    <span>{{ timeDisplay }}</span>
  </div>
</template>

<script>
export default {
  name: 'CalendarPublicTimeSlot',
  props: {
    timestamp: {
      type: Number,
    },
    isBooked: {
      type: Boolean,
    },
    isAvailable: {
      type: Boolean,
    },
    timeDisplay: {
      type: String,
    },
  }
};
</script>

<style lang="scss">
  .CalendarPublicTimeSlot {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    visibility: hidden;

    span {
      visibility: hidden;
    }

    &.available {
      visibility: visible;
      background-color: #38b559;

      &:hover {
        span {
          visibility: visible;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }

    &.available.booked {
      background-color: #F44336;
      background-image: repeating-linear-gradient(45deg,transparent,transparent 10px,hsla(0,0%,100%,.8) 0,hsla(0,0%,100%,.8) 15px);

      span {
        visibility: hidden;
      }
    }

    &.available.inBasket {
      background-color: #f49a28;
      background-image: repeating-linear-gradient(45deg,transparent,transparent 10px,hsla(0,0%,100%,.8) 0,hsla(0,0%,100%,.8) 15px);

      span {
        visibility: hidden;
      }
    }
  }
</style>
