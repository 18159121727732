<template>
  <div class="toast">
    <transition-group name="fade" tag="div" appear>
      <div v-for="n in queue" :key="n.id" class="ui message" :class="{ 'green': n.type === 'success', red: n.type === 'failure'}">
        <i class="close icon" @click="onDelete(n.id)"></i>
        <div class="header">{{n.msg}}</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  computed: {
    queue() {
      return this.$store.state.notifications.queue;
    }
  },
  methods: {
    onDelete(id) {
      this.$store.commit('DELETE_NOTIFICATION', id);
    }
  },
};
</script>

<style lang="scss">
.toast {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;

  .message {
    position: relative;
    width: 400px;

    &.fade-enter-active {
      transition: all .5s;
    }

    &.fade-leave-active {
      transition: all .2s;
    }

    &.fade-enter-active {
      opacity: 0;
    }

    &.fade-enter-to {
      opacity: 1;
    }
  }

}
</style>
