import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';

export default class CalendarApi {
  constructor(state) {
    this.storage = getStorage();
    this.state = state;
  }

  upload(file, name) {
    const filePath = `business_card/${this.state.authentication.authenticatedTeacherId}/${name}`;
    const storageRef = ref(this.storage, filePath);
    return uploadBytesResumable(storageRef, file);
  }
}
