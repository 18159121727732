export default {
  methods: {
  },
  computed: {
    isBasketCheckoutModalActive: {
      get() {
        return this.$store.state.basket.isBasketCheckoutModalActive;
      },
      set(value) {
        this.$store.commit('setBasketCheckoutModalState', value);
      },
    },
    isBasketAddItemModalActive: {
      get() {
        return this.$store.state.basket.isBasketAddItemModalActive;
      },
      set(value) {
        this.$store.commit('setBasketAddItemModalState', value);
      }
    },
    isChangeItemDateModalActive: {
      get() {
        return this.$store.state.basket.isChangeItemDateModalActive;
      },
      set(value) {
        this.$store.commit('SET_IS_CHANGE_ITEM_DATE_MODAL_ACTIVE', value);
      }
    },
    basketItems() {
      const items = {};
      const slotRange = this.slotMinutesRange * 60 * 1000;
      const { slotMinutesRange } = this;

      this.$store.state.basket.items.map((item) => {
        const slotsNumber = item.length / slotMinutesRange;
        items[item.timestamp] = 1;

        for (let i = 1; i < slotsNumber; i++) {
          items[item.timestamp + i * slotRange] = 1;
        }
      });

      return items;
    },
  }
};
