<template>
  <div class="CalendarPrivateDay">
    <CalendarPrivateHour v-for="hour in day.hours"
                         :hour="hour"
                         :current-week-events="currentWeekEvents"
                         :slot-minutes-range="slotMinutesRange"
                         :is-marked-as-available="isMarkedAsAvailable(day.weekDayNumber, hour[0].index)"
    />
  </div>
</template>

<script>
import CalendarPrivateHour from './CalendarPrivateHour.vue';

export default {
  name: 'CalendarPrivateDay',
  components: {
    CalendarPrivateHour,
  },
  computed: {
    availabilityTimes() {
      return this.$store.state.dashboard.userSettings.availabilityTimes;
    },
  },
  methods: {
    isMarkedAsAvailable(weekDayNumber, hourIndex) {
      const index = (weekDayNumber - 1) * 24 + hourIndex;
      return !!this.availabilityTimes[index];
    },
  },
  props: {
    day: {
      type: Object,
    },
    currentWeekEvents: {
      type: Object,
    },
    slotMinutesRange: {
      type: Number,
    },
  },
};
</script>

<style lang="scss">
</style>
