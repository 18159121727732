export default {
  computed: {
    students() {
      return this.$store.state.dashboard.students;
    },
    bookedSlots() {
      return this.$store.state.calendar.bookedSlots;
    },
    timezoneText() {
      const offset = this.moment().utcOffset() / 60;
      let text;

      if (offset >= 0) {
        text = `+${offset}`;
      } else {
        text = `${offset}`;
      }

      return `GMT${text} (${this.timezone})`;
    },
    timezone() {
      return this.momentGuess();
    },
    slotMinutesRange() {
      return this.$store.state.calendar.slotMinutesRange;
    },
    calendarMode() {
      return this.$store.state.calendar.mode;
    },
    isCalendarModeChange() {
      return this.$store.state.calendar.mode === 'change';
    },
  },
  methods: {
    printDay(timestamp) {
      return timestamp ? this.moment(timestamp).format('dddd (DD.MM.YYYY)') : '';
    },
    printHour(timestamp) {
      return timestamp ? this.moment(timestamp).format('HH:mm') : '';
    },
    getStudent(studentId) {
      let student;

      if (studentId) {
        student = this.students.find((student) => student.id === studentId);
      }

      if (!student) {
        student = {
          firstName: 'Użytkownik',
          lastName: 'Usunięty',
          location: ' ? ',
        };
      }

      return student;
    },
    getStudentName(val) {
      let studentId;

      if (val && val.studentId) {
        studentId = val.studentId;
      } else if (val && val.label) {
        return val.label;
      } else if (val && val.transactionId) {
        return 'Rezerwacja publiczna'
      } else if (val) {
        studentId = val;
      } else {
        return 'Not defined';
      }

      const student = this.getStudent(studentId);

      return `${student.firstName || ''} ${student.lastName || ''}`;
    },
    isExternalBooking(event) {
      return !!event.label;
    },
    getTimeDifferenceInWeeks(timestamp1, timestamp2) {
      const moment1 = this.moment(timestamp1);
      const moment2 = this.moment(timestamp2);
      return moment1.diff(moment2, 'weeks');
    },
    convertGeneralAvailabilityToLocalTimezone(generalAvailability, startTimestamp, teacherTimezone) {
      const starting = this.moment(startTimestamp);

      const localOffset = starting.utcOffset();
      const teacherOffset = starting.tz(teacherTimezone).utcOffset();
      const differenceInHours = (teacherOffset - localOffset) / 60;
      const generalAvailabilityInLocalTimezone = {};

      if (differenceInHours !== 0) {
        Object.keys(generalAvailability).forEach((availability) => {
          const updatedAvailability = this.getUpdatedAvailability(availability, differenceInHours);
          generalAvailabilityInLocalTimezone[updatedAvailability] = 1;
        });

        return generalAvailabilityInLocalTimezone;
      }
      return generalAvailability;
    },
    getUpdatedAvailability(availability, change) {
      let final = availability - change;

      if (final > 167) {
        final -= 167;
      } else if (final < 0) {
        final = 168 + final;
      }

      return final;
    },
    convertGeneralAvailabilityToTimestamps(generalAvailability, startTimestamp) {
      const starting = this.moment(startTimestamp);
      const timestamps = {};

      Object.keys(generalAvailability).forEach((availability) => {
        const hour = starting.clone().add(availability, 'h');

        for (let i = 0; i < 4; i++) {
          const slotTime = hour.clone().add(i * 15, 'm');
          timestamps[slotTime.valueOf()] = 1;
        }
      });

      return timestamps;
    },
    isTimestampBetween(start, end, timestamps) {
      return timestamps && Object.keys(timestamps).find((timestamp) => timestamp > start && timestamp < end);
    },
    convertGeneralAvailabilityToWeekDaysAndHours(generalAvailability) {
      const days = [0, 1, 2, 3, 4, 5, 6];

      return days.map((d) => {
        const start = d * 24;
        const hours = {};

        for (let i = 0; i < 24; i += 1) {
          if (generalAvailability[start + i]) {
            hours[i] = 1;
          }
        }

        return hours;
      });
    },
    getHoursRange(generalAvailability) {
      const days = this.convertGeneralAvailabilityToWeekDaysAndHours(generalAvailability);
      const daysWithMaxMin = days.map((day) => {
        const hours = Object.keys(day).map(Number);

        return [Math.min(...hours), Math.max(...hours)];
      });
      const hours = [].concat(...daysWithMaxMin).filter((x) => Number.isInteger(x));
      const min = Math.min(...hours) - 1;
      const max = Math.max(...hours) + 1;

      return {
        start: Math.max(0, min),
        end: Math.min(23, max),
      };
    },
    isNearFuture(timestamp) {
      return this.moment().valueOf() > this.moment(timestamp).subtract(12, 'hours').valueOf();
    },
  }
};
