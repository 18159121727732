import Api from '@/api/api';

const state = {
  teacherId: null,
  publicSettings: {
    bookingEnabledLoggedIn: null,
    bookingEnabledPublic: null,
    stripeChargesEnabled: null,
    stripeAccountId: null,
    paymentBankTransferEnabled: null,
    paymentStripeEnabled: null,
    paymentPrzelewy24Enabled: null,
    transferBankAccountName: null,
    transferBankAccountNumber: null,
  },
  studentId: null,
  studentData: null,
  studentPriceLists: [],
  teacherPriceLists: [],
  isLoadingStudentPriceLists: false,
  isLoadingTeacherPriceLists: false,
  isStudentAuthenticationVerified: false,
  isStudentVerified: false,
  prices: null,
};

const getters = {};

const actions = {
  loadStudentDashboardPriceLists({ state, commit }, studentId) {
    commit('SET_IS_LOADING_STUDENT_PRICE_LISTS', true);

    Api.students.loadPriceLists(studentId)
      .then((querySnapshot) => {
        const priceLists = [];
        querySnapshot.forEach((doc) => {
          const priceList = {
            ...doc.data(),
            id: doc.id
          };
          priceLists.push({ ...priceList });
        });
        commit('SET_STUDENT_PRICE_LISTS', priceLists);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit('SET_IS_LOADING_STUDENT_PRICE_LISTS', false);
      });
  },
  loadTeacherPriceLists({ commit }) {
    commit('SET_IS_LOADING_TEACHER_PRICE_LISTS', true);

    Api.tutors.loadTeacherPriceLists()
      .then((querySnapshot) => {
        const priceLists = [];
        querySnapshot.forEach((doc) => {
          const priceList = {
            id: doc.id,
            ...doc.data()
          };
          priceLists.push({ ...priceList });
        });
        commit('SET_TEACHER_PRICE_LISTS', priceLists);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit('SET_IS_LOADING_TEACHER_PRICE_LISTS', false);
      });
  },
};

const mutations = {
  SET_TUTOR_PAGE_TEACHER_ID(state, teacherId) {
    state.teacherId = teacherId;
  },
  SET_TUTOR_PAGE_PUBLIC_SETTINGS(state, settings) {
    state.publicSettings = settings;
  },
  SET_STUDENT_DATA(state, studentData) {
    state.studentData = studentData;
  },
  SET_STUDENT_PRICE_LISTS(state, priceLists) {
    state.studentPriceLists = priceLists;
  },
  SET_TEACHER_PRICE_LISTS(state, priceLists) {
    state.teacherPriceLists = priceLists;
  },
  SET_IS_LOADING_STUDENT_PRICE_LISTS(state, isLoading) {
    state.isLoadingStudentPriceLists = isLoading;
  },
  SET_IS_LOADING_TEACHER_PRICE_LISTS(state, isLoading) {
    state.isLoadingTeacherPriceLists = isLoading;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
